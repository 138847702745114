<div class="flex justify-around items-end p-2 bg-gray-100 h-full">
  <div
    *ngFor="let config of batteryConfigs"
    class="flex flex-col items-center mx-1">
    <!-- Indicators -->
    <div class="flex flex-col items-center mb-3">
      <!-- Watt Hours Indicator -->
      <div class="flex items-center bg-blue-500 text-white px-2 py-1 rounded-full text-xs mb-1">
        <img src="/assets/icons/zap-fast.svg" />
        {{ config.wattHours }} Wh
      </div>

      <!-- Price Indicator -->
      <div class="flex items-center bg-yellow-500 text-black px-2 py-1 rounded-full text-xs">
        <img src="/assets/icons/currency-euro-circle.svg" />
        {{ config.price }}€
      </div>
    </div>

    <!-- Battery Stack Visualization -->
    <div class="flex items-center">
      <!-- Removed continuous blue line -->

      <div class="flex flex-col">
        <div
          *ngFor="let i of counter(config.batteryCount)"
          class="flex items-center gap-0 justify-center">
          <!-- Individual blue border for each battery -->
          <div class="w-1 h-10 bg-blue-500 rounded-sm mr-1"></div>

          <img
            src="/assets/battery.png"
            class="w-20 h-11"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
