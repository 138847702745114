<div class="relative">
  @if (panelAmount() < 4) {
    <div class="absolute -top-4 -left-2 bg-white border rounded-lg flex flex-col items-center p-2 max-w-[60px] z-50">
      <img
        src="assets/icons/cloud-green.svg"
        alt=""
        class="mb-2" />
      <div class="text-[8px] text-center text-wrap">
        {{ 'potentials.cloudState' | transloco }}
      </div>
    </div>
  }

  <canvas
    class="min-h-[300px]"
    appChartjs
    [data]="datasets()"
    [options]="options"
    type="line">
  </canvas>
  @if (panelAmount() > 3) {
    <div class="absolute w-[93%] right-2 h-[90%] top-0 h-full bg-gradient-to-t from-gray-50 to-grey"></div>
  }
</div>
