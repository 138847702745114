import { Component, computed, input, output } from '@angular/core';
import { addIcons } from 'ionicons';
import { arrowUpOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/angular/standalone';
import { TranslocoModule } from '@jsverse/transloco';
import { Browser } from '@capacitor/browser';
import { PotentialService } from '../potential.service';

@Component({
  selector: 'app-potential-slide-6',
  templateUrl: './potential-slide-6.component.html',
  styleUrls: ['./potential-slide-6.component.scss'],
  imports: [IonIcon, TranslocoModule],
})
export class PotentialSlide6Component {
  readonly next = output<void>();

  panelAmount = input.required<number>();
  batteryAmount = input.required<number>();

  constructor(public potentialService: PotentialService) {
    addIcons({ arrowUpOutline });
  }

  goToUpgradeset() {
    Browser.open({ url: 'https://solakon.link/app-upgradeset' });
  }

  percentageIncrease = computed(() => {
    const steps = this.potentialService.calculatePotentialSteps(this.panelAmount(), this.batteryAmount());
    return steps[steps.length - 1].potential * 100;
  });
  getKwh = computed(() => {
    const steps = this.potentialService.calculatePotentialSteps(this.panelAmount(), this.batteryAmount());
    return steps[steps.length - 1].kwh;
  });
}
