@if (panelAmount() < 4) {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px] text-balance">{{ 'potentials.expandNowSave' | transloco }}</div>
    <div class="bg-[#F9FAFB] rounded p-4 mt-4">
      <div class="flex justify-center">
        <div class="flex gap-3">
          <div class="flex gap-1 items-center bg-[#ECFDF3] border border-[#ABEFC6] rounded-full px-2">
            <ion-icon
              class="text-[16px] text-[#17B26A]"
              name="arrow-up-outline"></ion-icon>
            <!--Jahresertrag + Stromtarif = Ersparnis-->
            <!--Jahresertrag + Stromtarif = Ersparnis-->
            <div class="text-[10px] text-[#067647]">{{ percentageIncrease().toFixed(0) }}%</div>
          </div>
          <div class="border border-[#E9EAEB] rounded-full px-2 text-[10px] flex items-center font-bold">
            +220&euro;
          </div>
        </div>
      </div>
      <div class="text-[16px] text-center font-bold mt-2">{{ 'potentials.savingsUpgradeSet' | transloco }}</div>
      <div class="text-center text-[16px] mt-2">
        {{ 'potentials.upgradeNow' | transloco: { amount: getKwh().toFixed(2) } }}
      </div>
      <div class="flex justify-center mt-4">
        <img
          src="assets/add-and-save.png"
          alt="" />
      </div>
      <button
        class="btn btn-primary btn-block rounded-2xl mt-4 text-[16px] text-balance"
        (click)="goToUpgradeset()">
        {{ 'potentials.expandNowSaveMore' | transloco }}
      </button>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-4">
    <!-- <div class="text-center font-bold text-[20px]">{{ 'potentials.expandNowSave' | transloco }}</div> -->
    <div class="bg-[#F9FAFB] rounded-2xl px-4 py-8 mt-4">
      <div class="flex justify-center">
        <div class="flex gap-3">
          <div class="flex gap-1 items-center bg-[#2088FD] rounded-full px-2 py-1">
            <img
              src="/assets/icons/zap-fast.svg"
              alt="" />
            <div class="text-[10px] text-white">{{ percentageIncrease().toFixed(0) }}%</div>
          </div>
          <div class="bg-[#FFCA22] rounded-full px-2 py-1 text-[10px] flex items-center font-bold">
            <img
              src="/assets/icons/currency-euro-circle.svg"
              alt="" />+220&euro;
          </div>
        </div>
      </div>
      <div
        class="text-[24px] text-center font-bold mt-2"
        [innerHTML]="'potentials.expandNowSave' | transloco"></div>
      <div class="text-center text-[18px] mt-2 px-3">
        {{ 'potentials.upgradeNowFour' | transloco: { amount: getKwh().toFixed(2) } }}
      </div>
      <div class="flex justify-center mt-4 p-6">
        <img
          src="assets/batterybig.png"
          class="w-full"
          alt="" />
      </div>
      <button
        class="btn btn-primary btn-block rounded-2xl mt-4 text-[16px] text-balance"
        (click)="goToUpgradeset()">
        {{ 'potentials.expandNowSaveMore' | transloco }}
      </button>
    </div>
  </div>
}
