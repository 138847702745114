import { Component, input, OnInit, output } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslocoModule } from '@jsverse/transloco';
import { addIcons } from 'ionicons';
import { arrowUpOutline } from 'ionicons/icons';

@Component({
  selector: 'app-potential-slide-4',
  templateUrl: './potential-slide-4.component.html',
  styleUrls: ['./potential-slide-4.component.scss'],
  imports: [TranslocoModule],
})
export class PotentialSlide4Component implements OnInit {
  readonly next = output<void>();
  batteryAmount = input.required<number>();
  panelAmount = input.required<number>();
  constructor() {
    addIcons({ arrowUpOutline });
  }

  ngOnInit() {}

  goToUpgradeset() {
    Browser.open({ url: 'https://solakon.link/app-upgradeset' });
  }
}
