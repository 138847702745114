import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, input, OnInit, output, viewChild } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { addIcons } from 'ionicons';
import { chevronBackOutline, chevronForwardOutline, star } from 'ionicons/icons';

@Component({
  selector: 'app-potential-slide-5',
  templateUrl: './potential-slide-5.component.html',
  styleUrls: ['./potential-slide-5.component.scss'],
  imports: [CommonModule, TranslocoModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PotentialSlide5Component implements OnInit {
  readonly next = output<void>();
  batteryAmount = input.required<number>();
  panelAmount = input.required<number>();
  readonly reviewSwiperRef = viewChild<ElementRef>('reviewSwiper');
  constructor() {
    addIcons({ star, chevronBackOutline, chevronForwardOutline });
  }

  ngOnInit() {}

  slideNext() {
    this.reviewSwiperRef()?.nativeElement.swiper.slideNext();
  }
  slidePrev() {
    this.reviewSwiperRef()?.nativeElement.swiper.slidePrev();
  }
}
