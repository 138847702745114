@if (panelAmount() < 4) {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.howToUsePotential' | transloco }}</div>
    <div class="text-[16px] text-center mt-4 text-balance">
      {{ 'potentials.slide2-text1' | transloco }}
    </div>
    <div class="bg-mainBg rounded px-2 py-4 mt-4">
      <div
        class="overlay-img z-20 flex justify-center items-center px-4 py-6 border-t border-b border-dashed border-[#ABEFC6]">
        <div class="flex justify-between w-full items-center">
          <div>&nbsp;</div>
          <div class="text-[10px] text-[#067647] flex gap-1 items-center">
            <img
              src="assets/icons/battery-potentiale.svg"
              alt="" />
            <div>{{ 'potentials.storingExcessEnergy' | transloco }}</div>
          </div>
          <div>
            <ion-icon
              id="click-trigger"
              name="help-circle-outline"></ion-icon>
            <ion-popover
              trigger="click-trigger"
              triggerAction="click">
              <ng-template>
                <ion-content class="ion-padding text-[16px]">{{ 'potentials.slide2-text2' | transloco }}</ion-content>
              </ng-template>
            </ion-popover>
          </div>
        </div>
      </div>
      <app-potential-slide-2-chart
        [data]="data()"
        [panelAmount]="panelAmount()"></app-potential-slide-2-chart>
      <div class="px-2 flex flex-col gap-2 mt-3">
        <div class="flex gap-1 items-center">
          <div class="w-2 h-2 rounded-full bg-[#D7EFE5]"></div>
          <div
            class="text-[16px]"
            [innerHTML]="'potentials.yieldWithout' | transloco"></div>
        </div>
        @if (data()[1]) {
          <div class="flex gap-1 items-center">
            <div
              class="w-2 h-2 rounded-full"
              [ngClass]="data()?.[2] ? 'bg-[#17B26A]' : 'bg-[#FFCA22]'"></div>
            <div
              class="text-[16px]"
              [innerHTML]="'potentials.yield1modul' | transloco"></div>
          </div>
        }
        @if (data()[2]) {
          <div class="flex gap-1 items-center">
            <div class="w-2 h-2 rounded-full bg-[#FFCA22]"></div>
            <div
              class="text-[16px]"
              [innerHTML]="'potentials.yield2modul' | transloco"></div>
          </div>
        }
        <div class="bg-[#ECFDF3] border border-[#ABEFC6] rounded p-2 flex gap-2 items-center mt-4">
          <img
            src="assets/growatt-battery.png"
            class="w-12 mr-2"
            alt="" />
          <div>
            <div class="text-[16px] font-[500]">{{ 'potentials.saveNowUseLater' | transloco }}</div>
            <div class="text-[14px] text-[#535862]">{{ 'potentials.stopWastingEnergy' | transloco }}</div>
            <div class="bg-[#17B26A] text-white flex gap-1 items-center w-fit rounded-full px-2 py-1 mt-1">
              <ion-icon
                class="text-[16px]"
                name="arrow-up-outline"></ion-icon>
              <div class="font-[500] text-[14px]">3951&euro;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.howToUsePotentialFour' | transloco }}</div>
    <div class="text-[16px] text-center mt-4 text-balance">
      {{ 'potentials.slide2-text1-four' | transloco }}
    </div>
    <div class="flex flex-row justify-between">
      <div class="bg-[#DCE8F5] border rounded-lg flex flex-row items-center py-0 px-2">
        <img
          src="assets/icons/sun.svg"
          alt="" />
        <div class="text-[12px] text-center text-wrap">
          {{ 'potentials.cloudState' | transloco }}
        </div>
      </div>
      <div class="bg-white border rounded-lg flex flex-row items-center py-0 px-2 gap-2">
        <div class="text-[12px] text-center text-wrap text-[#2088FD]">
          {{ 'potentials.question-text' | transloco }}
        </div>
        <ion-icon
          name="help-circle-outline"
          id="click-trigger"
          style="color: #2088fd"></ion-icon>
        <ion-popover
          trigger="click-trigger"
          triggerAction="click">
          <ng-template>
            <ion-content class="ion-padding text-[16px]">{{ 'potentials.slide2-text2' | transloco }}</ion-content>
          </ng-template>
        </ion-popover>
      </div>
    </div>
    <div class="bg-mainBg rounded px-2 py-4 mt-4">
      <div class="overlay-area">
        <div
          class="overlay-img-four z-20 flex justify-center items-center px-4 py-3 border-t border-dashed border-[#2088FD] relative">
          <div class="absolute -left-10 -top-1 text-[10px] text-[#2088FD]">1000W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#2088FD] flex gap-1 items-center">
              <img
                src="assets/icons/battery-charging-2.svg"
                alt="" />
            </div>
          </div>
        </div>
        <div
          class="overlay-img-four z-20 flex justify-center items-center px-4 py-3 border-t border-dashed border-[#2088FD] relative">
          <div class="absolute -left-10 -top-1 text-[10px] text-[#2088FD]">900W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#2088FD] flex gap-1 items-center">
              <img
                src="assets/icons/battery-charging-2.svg"
                alt="" />
            </div>
          </div>
        </div>
        <div
          class="overlay-img-other z-20 flex justify-center items-center px-4 py-4 border-b border-dashed border-[#D5D7DA] relative">
          <div class="absolute -left-10 -top-1 text-[10px]">800W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#D5D7DA] flex gap-1 items-center"></div>
          </div>
        </div>
        <div
          class="overlay-img-other z-20 flex justify-center items-center px-4 py-4 border-b border-dashed border-[#D5D7DA] relative">
          <div class="absolute -left-10 -top-1 text-[10px]">700W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#D5D7DA] flex gap-1 items-center"></div>
          </div>
        </div>
        <div
          class="overlay-img-other z-20 flex justify-center items-center px-4 py-4 border-b border-dashed border-[#D5D7DA] relative">
          <div class="absolute -left-10 -top-1 text-[10px]">600W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#D5D7DA] flex gap-1 items-center"></div>
          </div>
        </div>
        <div
          class="overlay-img-other z-20 flex justify-center items-center px-4 py-5 border-b border-dashed border-[#D5D7DA] relative">
          <div class="absolute -left-10 -top-1 text-[10px]">500W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#D5D7DA] flex gap-1 items-center"></div>
          </div>
        </div>
        <div
          class="overlay-img-other z-20 flex justify-center items-center px-4 py-6 border-b border-dashed border-[#D5D7DA] relative">
          <div class="absolute -left-10 -top-1 text-[10px]">250W</div>
          <div class="flex justify-between w-full items-center">
            <div class="text-[10px] text-[#D5D7DA] flex gap-1 items-center"></div>
          </div>
        </div>
      </div>
      <app-potential-slide-2-chart-four
        [data]="data()"
        [panelAmount]="panelAmount()"></app-potential-slide-2-chart-four>
      <div class="px-2 flex flex-col gap-2 mt-3 bg-white border rounded-xl py-4 px-6">
        <div class="flex gap-1 items-center">
          <div class="w-2 h-2 rounded-full border-[#FFCA22] border-2"></div>
          <div
            class="text-[16px]"
            [innerHTML]="'potentials.yieldWithout' | transloco"></div>
        </div>
        <div class="flex gap-1 items-center">
          <div
            class="w-2 h-2 rounded-full"
            [ngClass]="data()?.[2] ? 'bg-[#FFCA22]' : 'bg-[#2088FD]'"></div>
          <div
            class="text-[16px]"
            [innerHTML]="'potentials.yield1modul' | transloco"></div>
        </div>
        <div class="flex gap-1 items-center">
          <div class="w-2 h-2 rounded-full bg-[#2088FD]"></div>
          <div
            class="text-[16px]"
            [innerHTML]="'potentials.yield1modul' | transloco"></div>
        </div>
      </div>
    </div>
  </div>
}
