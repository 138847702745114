import { Component, computed, input } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { ChartjsDirective } from 'src/app/directives/chartjs.directive';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { PotentialService } from '../../potential.service';

Chart.register(annotationPlugin);

@Component({
  selector: 'app-potential-slide-1-chart',
  templateUrl: './potential-slide-1-chart.component.html',
  styleUrls: ['./potential-slide-1-chart.component.scss'],
  imports: [ChartjsDirective, CommonModule, TranslocoModule],
})
export class PotentialSlide1ChartComponent {
  potentials = input.required<number[]>();
  data = input.required<number[]>();
  panelAmount = input.required<number>();

  datasets = computed(() => ({
    labels: this.potentials(),
    datasets: [{ data: this.data(), barPercentage: 1.15, borderRadius: 10 }],
  }));

  options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: { border: { display: false }, ticks: { display: false }, grid: { display: false } },
      y: { display: false, grid: { display: false } },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false,
      },
    },
    backgroundColor: ['#17B26A26', '#67CE9D', '#17B26A'],
  };

  constructor(private potentialService: PotentialService) {}
}
