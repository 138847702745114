@if (panelAmount() < 4) {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.experiencesCustomerProjects' | transloco }}</div>
    <div class="bg-[#F9FAFB] rounded p-4 mt-4">
      <swiper-container
        #reviewSwiper
        pagination="true"
        pagination-el="#reviewPagination">
        <swiper-slide>
          <div class="flex justify-center my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText1' | transloco }}
            </div>
          </div>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-1.png"
              alt="" />
          </div>
          <div class="font-bold flex justify-center mt-4">Thomas Groß</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate1' | transloco }}</div>
        </swiper-slide>
        <swiper-slide>
          <div class="flex justify-center mt-4 my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText2' | transloco }}
            </div>
          </div>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-2.png"
              alt="" />
          </div>
          <div class="font-bold flex justify-center mt-4">Manfred Hausen</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate2' | transloco }}</div>
        </swiper-slide>
        <swiper-slide>
          <div class="flex justify-center mt-4 my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText3' | transloco }}
            </div>
          </div>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-3.png"
              alt="" />
          </div>
          <div class="font-bold flex justify-center mt-4">Manuela Sachse</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate3' | transloco }}</div>
        </swiper-slide>
      </swiper-container>
      <div class="flex justify-center items-center mt-4 relative">
        <div class="w-10 h-11 flex justify-center items-center absolute z-20 left-0 bottom-0">
          <div
            class="flex items-center p-2 bg-black justify-center rounded-full text-primary w-full aspect-square h-[40px]"
            *ngIf="reviewSwiperRef()?.nativeElement?.swiper?.activeIndex !== 0"
            (click)="slidePrev()">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </div>
        </div>
        <div class="w-10 h-11 flex justify-center items-center absolute right-0 z-20 bottom-0">
          <div
            class="flex items-center p-2 bg-black justify-center rounded-full text-primary w-full h-[40px] aspect-square"
            *ngIf="reviewSwiperRef()?.nativeElement?.swiper?.activeIndex !== 2"
            (click)="slideNext()">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
        <div id="reviewPagination"></div>
      </div>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-4">
    <!-- <div class="text-center font-bold text-[20px]">{{ 'potentials.experiencesCustomerProjects' | transloco }}</div> -->
    <div class="rounded p-4 mt-4">
      <swiper-container
        #reviewSwiper
        pagination="true"
        pagination-el="#reviewPagination">
        <swiper-slide>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-1.png"
              alt="" />
          </div>
          <div class="flex justify-center my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="font-bold flex justify-center mt-4">Thomas Groß</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate1' | transloco }}</div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText1' | transloco }}
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-2.png"
              alt="" />
          </div>
          <div class="flex justify-center mt-4 my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="font-bold flex justify-center mt-4">Manfred Hausen</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate2' | transloco }}</div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText2' | transloco }}
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flex justify-center mt-10">
            <img
              class="rounded"
              src="assets/kunde-3.png"
              alt="" />
          </div>
          <div class="flex justify-center mt-4 my-4">
            <ion-icon
              *ngFor="let item of [1, 2, 3, 4, 5]"
              class="text-primary ml-1"
              name="star"></ion-icon>
          </div>
          <div class="font-bold flex justify-center mt-4">Manuela Sachse</div>
          <div class="text-[16px] flex justify-center mt-3">{{ 'potentials.experienceDate3' | transloco }}</div>
          <div class="flex justify-center">
            <div class="text-center max-w-72">
              {{ 'potentials.experienceText3' | transloco }}
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div class="flex justify-center items-center mt-4 relative">
        <div class="w-10 h-11 flex justify-center items-center absolute z-20 left-0 bottom-0">
          <div
            class="flex items-center p-2 bg-black justify-center rounded-full text-primary w-full aspect-square h-[40px]"
            *ngIf="reviewSwiperRef()?.nativeElement?.swiper?.activeIndex !== 0"
            (click)="slidePrev()">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </div>
        </div>
        <div class="w-10 h-11 flex justify-center items-center absolute right-0 z-20 bottom-0">
          <div
            class="flex items-center p-2 bg-black justify-center rounded-full text-primary w-full h-[40px] aspect-square"
            *ngIf="reviewSwiperRef()?.nativeElement?.swiper?.activeIndex !== 2"
            (click)="slideNext()">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
        <div id="reviewPagination"></div>
      </div>
    </div>
  </div>
}
