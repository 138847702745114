@if (panelAmount() < 4) {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.whyExpand' | transloco }}</div>
    <div class="flex gap-3 mt-3 items-start">
      <img
        src="assets/icons/sun.svg"
        alt="" />
      <div class="text-[16px]">
        {{ 'potentials.sunnyDays' | transloco }}
      </div>
    </div>
    <div class="flex gap-3 mt-2 items-start">
      <img
        src="assets/icons/cloud.svg"
        alt="" />
      <div class="text-[16px]">
        {{ 'potentials.cloudyWeather' | transloco }}
      </div>
    </div>
    <div class="bg-[#F9FAFB] rounded p-4 mt-4">
      @if (steps()[2]) {
        <div class="flex gap-4">
          <div class="w-8 bg-[#FFCA22] rounded-t"></div>
          <div class="mt-2">
            <div class="flex gap-1">
              <div class="font-bold text-[16px]">{{ steps()[2].kwh | number: '1.0-2' }} kWh</div>
              <div class="flex gap-1 items-center bg-[#ECFDF3] border border-[#ABEFC6] rounded-full px-2">
                <ion-icon
                  class="text-[16px] text-[#17B26A]"
                  name="arrow-up-outline"></ion-icon>
                <div class="text-[10px] text-[#067647]">{{ steps()[2].potential * 100 | number: '1.0-2' }}%</div>
              </div>
            </div>
            <div class="text-[10px] mt-1 text-balance">{{ 'potentials.extension2' | transloco }}</div>
          </div>
        </div>
      }
      @if (steps()[1]) {
        <div class="flex gap-4">
          <div
            [ngClass]="steps()[2] ? 'bg-[#FFCA22]' : 'bg-[#1EA14E]  rounded-t'"
            class="w-8">
            <div class="w-full h-full rounded-t bg-[#1EA14E]"></div>
          </div>
          <div class="mt-2">
            <div class="flex gap-1">
              <div class="font-bold text-[16px]">{{ steps()[1].kwh | number: '1.0-2' }} kWh</div>
              <div class="flex gap-1 items-center bg-[#ECFDF3] border border-[#ABEFC6] rounded-full px-2">
                <ion-icon
                  class="text-[16px] text-[#17B26A]"
                  name="arrow-up-outline">
                </ion-icon>
                <div class="text-[10px] text-[#067647]">{{ steps()[1].potential * 100 | number: '1.0-2' }}%</div>
              </div>
            </div>
            <div class="text-[10px] mt-1 text-balance">
              {{ 'potentials.extension1' | transloco }}
            </div>
          </div>
        </div>
      }

      @if (steps()[0]) {
        <div class="flex gap-4">
          <div class="w-8 bg-[#1EA14E]">
            <div class="w-full h-full bg-[#17B26A] rounded-t"></div>
          </div>
          <div class="mt-2">
            <div class="flex gap-1">
              <div class="font-bold text-[16px]">{{ steps()[0].kwh | number: '1.0-2' }} kWh</div>
              <!--<div class="flex gap-1 items-center bg-[#faf4f4] border border-[#7D7D7D] rounded-full px-2">
              <ion-icon
                class="text-[16px] text-[#7D7D7D]"
                name="arrow-up-outline"></ion-icon>
              <div class="text-[10px] text-[#7D7D7D]">{{ steps()[0].potential * 100 | number: '1.0-2' }}%</div>
            </div>-->
            </div>
            <div class="text-[10px] mt-1 text-balance">{{ 'potentials.extrapolated' | transloco }}</div>
          </div>
        </div>
      }
    </div>
    <div class="text-[16px] text-center mt-4 text-balance">
      {{ 'potentials.slide3-text1' | transloco }}
    </div>
    <div class="flex justify-center mt-4">
      <video
        class="rounded w-full"
        width="282"
        height="143"
        autoplay
        playsinline
        muted>
        <source src="assets/videos/potential.mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.whyExpandFour' | transloco }}</div>
    <div class="text-[16px] text-center mt-4 text-balance">
      {{ 'potentials.slide3-text1-four' | transloco }}
    </div>
    <app-battery-stack></app-battery-stack>
  </div>
}
