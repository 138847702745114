import { CommonModule, NgClass } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Style } from '@capacitor/status-bar';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonToolbar,
  NavController,
} from '@ionic/angular/standalone';
import { TranslocoModule } from '@jsverse/transloco';
import { setStatusBarStyle } from '@services/helper.service';
import { addIcons } from 'ionicons';
import { arrowForwardOutline, chevronBackOutline, closeCircleOutline } from 'ionicons/icons';
import { FadeInOut } from 'src/app/animations/fadeInOutAnimation';
import { VisibilityDirective } from 'src/app/directives/visibility.directive';
import { PotentialSlide1Component } from './potential-slide-1/potential-slide-1.component';
import { PotentialSlide2Component } from './potential-slide-2/potential-slide-2.component';
import { PotentialSlide3Component } from './potential-slide-3/potential-slide-3.component';
import { PotentialSlide4Component } from './potential-slide-4/potential-slide-4.component';
import { PotentialSlide5Component } from './potential-slide-5/potential-slide-5.component';
import { PotentialSlide6Component } from './potential-slide-6/potential-slide-6.component';
import { PotentialService } from './potential.service';
import { TitleComponent } from 'src/app/components/general/title/title.component';

@Component({
  selector: 'app-potential',
  templateUrl: './potential.page.html',
  styleUrls: ['./potential.page.scss'],
  animations: [FadeInOut(0, 300, false)],
  imports: [
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonToolbar,
    CommonModule,
    IonIcon,
    TranslocoModule,
    PotentialSlide1Component,
    PotentialSlide2Component,
    PotentialSlide3Component,
    PotentialSlide4Component,
    PotentialSlide5Component,
    PotentialSlide6Component,
    TranslocoModule,
    VisibilityDirective,
    IonFooter,
    RouterModule,
    NgClass,
    TitleComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PotentialPage {
  currentStep = 1; // Track current step (1 to 6)

  constructor(
    public potentialService: PotentialService,
    private navCtrl: NavController
  ) {
    addIcons({ closeCircleOutline, arrowForwardOutline, chevronBackOutline });
  }

  onVisible(visible: boolean) {
    if (visible) {
      setStatusBarStyle(Style.Light);
    }
  }

  slideNext() {
    if (this.currentStep < 6) {
      this.currentStep++;
    }
  }

  slidePrev() {
    if (this.currentStep > 1) {
      this.currentStep--;
    } else {
      this.goBack();
    }
  }

  goToUpgradeset() {
    Browser.open({ url: 'https://solakon.link/app-upgradeset' });
  }

  goBack() {
    this.navCtrl.navigateBack(['/tabs/dashboard']);
  }
}
