import { Component, computed, Input, input } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { ChartjsDirective } from 'src/app/directives/chartjs.directive';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { PotentialService } from '../../potential.service';

Chart.register(annotationPlugin);

@Component({
  selector: 'app-potential-slide-2-chart',
  templateUrl: './potential-slide-2-chart.component.html',
  styleUrls: ['./potential-slide-1-chart.component.scss'],
  imports: [ChartjsDirective, CommonModule, TranslocoModule],
})
export class PotentialSlide2ChartComponent {
  potentials = input.required<number[]>();
  data = input.required<number[]>();
  color = input.required<string[]>();
  datasets = computed(() => ({
    labels: [this.potentials()[0]],
    datasets: [{ data: this.data(), barPercentage: 1.15, borderRadius: 0, backgroundColor: this.color(), }],
  }));
  options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: { border: { display: false }, ticks: { display: false }, grid: { display: true},
      min: 0,
      max: 800, },
      y: { display: false, grid: { display: true }, min:10 },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false,
      },
    },
  };

  constructor(private potentialService: PotentialService) {}
}
