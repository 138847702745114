import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

interface BatteryConfig {
  wattHours: number;
  price: number;
  batteryCount: number;
}

@Component({
  selector: 'app-battery-stack',
  templateUrl: './battery-stack.component.html',
  styleUrls: ['./battery-stack.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class BatteryStackComponent {
  batteryConfigs: BatteryConfig[] = [
    { wattHours: 2048, price: 220, batteryCount: 1 },
    { wattHours: 4096, price: 440, batteryCount: 2 },
    { wattHours: 6144, price: 660, batteryCount: 3 },
    { wattHours: 8192, price: 880, batteryCount: 4 }
  ];
  
  // Helper method to generate array for ngFor
  counter(count: number): Array<number> {
    return new Array(count);
  }
}