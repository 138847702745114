import { Component, computed, input, output } from '@angular/core';
import { addIcons } from 'ionicons';
import { arrowUpOutline, helpCircleOutline } from 'ionicons/icons';
import { IonContent, IonIcon, IonPopover } from '@ionic/angular/standalone';
import { PotentialSlide2ChartComponent } from './potential-slide-2-chart/potential-slide-2-chart.component';
import { PotentialSlide2ChartFourComponent } from './potential-slide-2-chart/potential-slide-2-chart-four.component';
import { TranslocoModule } from '@jsverse/transloco';
import { PotentialService } from '../potential.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-potential-slide-2',
  templateUrl: './potential-slide-2.component.html',
  styleUrls: ['./potential-slide-2.component.scss'],
  imports: [IonContent, IonPopover, IonIcon, PotentialSlide2ChartComponent, PotentialSlide2ChartFourComponent, TranslocoModule, CommonModule],
})
export class PotentialSlide2Component {
  readonly next = output<void>();

  batteryAmount = input.required<number>();
  panelAmount = input.required<number>();

  constructor(private potentialService: PotentialService) {
    addIcons({ arrowUpOutline, helpCircleOutline });
  }

  data = computed(() => {
    const maxPanels = 4;
    const currentPotential = this.potentialService.getPotential(
      this.panelAmount(),
      this.batteryAmount()
    );
    const baseValue = 800;
    const data = [baseValue];
  
    if (this.panelAmount() + 1 <= maxPanels) {
      const potentialOneMore = this.potentialService.getPotential(
        this.panelAmount() + 1,
        this.batteryAmount()
      );
      const oneMoreValue = baseValue * (1 + (potentialOneMore - currentPotential));
      data.push(oneMoreValue);
    }
  
    if (this.panelAmount() + 2 <= maxPanels) {
      const potentialTwoMore = this.potentialService.getPotential(
        this.panelAmount() + 2,
        this.batteryAmount()
      );
      const twoMoreValue = baseValue * (1 + (potentialTwoMore - currentPotential));
      data.push(twoMoreValue);
    }
  
    return data.sort((a, b) => a - b);
  });
}
