<ion-header class="ion-no-border">
  @if (potentialService.selectedGroup()!.solarPanelAmount < 4) {
    <ion-toolbar class="px-2">
      <ion-buttons slot="end">
        <ion-button (click)="goBack()">
          <ion-icon
            class="text-black"
            slot="icon-only"
            name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="block">
        <div class="flex justify-center items-center py-4">
          <div class="flex space-x-2">
            <div
              *ngFor="let step of [1, 2, 3, 4, 5, 6]; let i = index"
              class="w-2 h-2 rounded-full"
              [ngClass]="{ 'bg-primary': currentStep === step, 'bg-gray-300': currentStep !== step }"></div>
          </div>
        </div>
      </ion-title>
      <button
        class="absolute -top-2 -left-4 bg-white rounded-xl border shadow-none mx-5 p-1 items-center justify-center flex mt-4 w-8 h-8"
        (click)="slidePrev()"
        *ngIf="currentStep > 0">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </button>
    </ion-toolbar>
  } @else {
    <ion-toolbar class="w-full">
      <ion-buttons slot="end">
        <ion-button (click)="goBack()">
          <ion-icon
            class="text-black"
            slot="icon-only"
            name="close-circle-outline"></ion-icon>
        </ion-button> </ion-buttons
      ><app-title
        [title]=""
        [showBackButton]="false"
        [currentStep]="currentStep"
        class="block w-full"
        [totalSteps]="6"></app-title
    ></ion-toolbar>
  }
</ion-header>

<ion-content
  appVisibility
  (visible)="onVisible($event)"
  [fullscreen]="true">
  <div class="page-inner bg-mainBg mt-4">
    <ng-container *ngIf="potentialService.selectedGroup()">
      <ng-container [ngSwitch]="currentStep">
        <app-potential-slide-1
          *ngSwitchCase="1"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"
          (next)="slideNext()"></app-potential-slide-1>

        <app-potential-slide-2
          *ngSwitchCase="2"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"></app-potential-slide-2>

        <app-potential-slide-3
          *ngSwitchCase="3"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"></app-potential-slide-3>

        <app-potential-slide-4
          *ngSwitchCase="4"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"></app-potential-slide-4>
        <app-potential-slide-5
          *ngSwitchCase="5"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"></app-potential-slide-5>

        <app-potential-slide-6
          *ngSwitchCase="6"
          [panelAmount]="potentialService.selectedGroup()!.solarPanelAmount"
          [batteryAmount]="potentialService.selectedGroup()!.batteries.length"></app-potential-slide-6>
      </ng-container>
    </ng-container>
  </div>
</ion-content>

@if (potentialService.selectedGroup()!.solarPanelAmount < 4) {
  <ion-footer
    class="ion-no-border shadow-lg bg-mainBg"
    *ngIf="currentStep !== 6">
    <ion-toolbar>
      <div class="flex w-full flex-col gap-2 p-4 mt-2">
        <button
          *ngIf="currentStep === 3"
          (click)="goToUpgradeset()"
          class="btn btn-primary rounded-xl flex-grow text-black text-[14px]">
          {{ 'potentials.getUpgradeNow' | transloco }}
        </button>

        <button
          *ngIf="currentStep === 1"
          class="btn btn-primary rounded-xl flex-grow text-black text-[16px]"
          (click)="slideNext()">
          {{ 'common.continue_initial' | transloco }}
        </button>

        <button
          *ngIf="currentStep !== 6 && currentStep !== 1"
          class="btn btn-primary rounded-xl flex-grow"
          (click)="slideNext()">
          {{ 'common.continue' | transloco }}
        </button>
      </div>

      <div
        *ngIf="currentStep === 1"
        class="flex justify-center items-center gap-2 mb-4 btn bg-white border border-primary rounded-xl mx-5"
        [routerLink]="['/potential/order-number']"
        [queryParams]="{ groupId: potentialService.selectedGroup()?.id }">
        <div class="font-semibold text-[16px] text-primary">
          {{ 'potentials.alreadyHaveUpgrade' | transloco }}
        </div>
      </div>

      <div
        class="flex w-full gap-2 px-5 mb-4"
        *ngIf="currentStep === 3">
        <button
          class="btn text-primary bg-[#FFCA221A] !border !border-primary rounded text-[16px] w-full"
          [routerLink]="['/potential/order-number']"
          [queryParams]="{ groupId: potentialService.selectedGroup()?.id }">
          {{ 'potentials.alreadyHaveUpgrade' | transloco }}
        </button>
      </div>
    </ion-toolbar>
  </ion-footer>
} @else {
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <div class="flex w-full flex-col gap-2 p-4 bg-white">
        <button
          *ngIf="currentStep === 1"
          class="btn btn-primary rounded-xl flex-grow text-black text-[16px]"
          (click)="slideNext()">
          {{ 'common.continue_initial' | transloco }}
        </button>
        <div class="flex flex-row justify-between">
          <button
            *ngIf="currentStep !== 1"
            class="btn bg-white rounded-xl"
            (click)="slidePrev()">
            <ion-icon name="chevron-back-outline"></ion-icon>
            {{ 'common.back' | transloco }}
          </button>

          <a
            *ngIf="currentStep !== 6 && currentStep !== 1"
            class="btn bg-white border-1 border-primary text-[#FFCA22] rounded-xl"
            (click)="slideNext()">
            {{ 'common.continue' | transloco }}
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </a>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
}
