<div class="flex flex-col gap-4">
  @if (panelAmount() < 4) {
    <div class="text-center font-bold text-[20px]">{{ 'potentials.howExpand' | transloco }}</div>
    <div class="text-[16px] text-center mt-4 text-balance">
      {{ 'potentials.slide4-text1' | transloco }}
    </div>
    <div class="text-[16px] text-center font-bold mt-6">{{ 'potentials.findOutHow' | transloco }}</div>
  } @else {
    <div class="text-center font-bold text-[20px]">{{ 'potentials.howExpandFour' | transloco }}</div>
    <div
      class="text-[16px] text-center mt-4 text-balance"
      [innerHTML]="'potentials.slide4-text1-four' | transloco"></div>
  }
  <div
    style="position: relative; padding-top: 56.25%"
    class="rounded overflow-hidden bg-slate-400">
    <video
      src="/assets/battery_upgrade set.mov"
      controls
      autoplay
      muted
      loop
      style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%"></video>
  </div>
</div>
