<div>
  <canvas
    class="min-h-[300px]"
    appChartjs
    [data]="datasets()"
    [options]="options"
    type="bar">
  </canvas>
</div>
<div class="flex">
  @if (data()[0]) {
    <div class="w-full flex flex-col items-center">
      <div class="font-bold text-[16px]">{{ data()[0] | number: '1.2-2' }} kWh</div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/icons/potential-icon-gray.svg"
          alt="" />
        <div class="text-[16px] text-[#D0D5DD]">{{ potentials()[0] * 100 }}%</div>
      </div>
      <div class="text-[10px] text-[#7D7D7D] text-center text-balance">
        {{ 'potentials.extrapolated' | transloco }}
      </div>
    </div>
  }
  @if (data()[1]) {
    <div class="w-full flex flex-col items-center">
      <div class="font-bold text-[16px]">{{ data()[1] | number: '1.2-2' }} kWh</div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/icons/potential-icon-lightgreen.svg"
          alt="" />
        <div class="text-[16px] text-[#67CE9D]">{{ potentials()[1] * 100 }}%</div>
      </div>
      <div class="text-[10px] text-[#7D7D7D] text-center text-balance">{{ 'potentials.extension1' | transloco }}</div>
    </div>
  }
  @if (data()[2]) {
    <div class="w-full flex flex-col items-center">
      <div class="font-bold text-[16px]">{{ data()[2] | number: '1.2-2' }} kWh</div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/icons/potential-icon-lightgreen.svg"
          alt="" />

        <div class="text-[16px] text-[#17B26A]">{{ potentials()[2] * 100 }}%</div>
      </div>
      <div class="text-[10px] text-[#7D7D7D] text-center text-balance">{{ 'potentials.extension2' | transloco }}</div>
    </div>
  }
</div>
