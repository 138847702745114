<div>
  <canvas
    class="max-h-[40px]"
    appChartjs
    [data]="datasets()"
    [options]="options"
    type="bar">
  </canvas>
</div>
<div class="flex">
  <div class="w-full flex flex-col items-start">
    <div class="text-[10px] text-[#7D7D7D] text-center text-balance">
      {{ 'potentials.extrapolatedFour' | transloco }}
    </div>
  </div>
  <div class="w-full flex flex-col items-center">
    <div class="text-[10px] text-[#7D7D7D] text-center text-balance">
      {{ 'potentials.extension1Four' | transloco }}
    </div>
  </div>
  <div class="w-full flex flex-col items-end">
    <div class="text-[10px] text-[#7D7D7D] text-center text-balance">
      {{ 'potentials.extension2Four' | transloco }}
    </div>
  </div>
</div>
