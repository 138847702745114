import { Component, computed, input, output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { addIcons } from 'ionicons';
import { add, arrowForwardOutline } from 'ionicons/icons';
import { PotentialService } from '../potential.service';
import { PotentialSlide1ChartComponent } from './potential-slide-1-chart/potential-slide-1-chart.component';
import { PotentialSlide2ChartComponent } from './potential-slide-1-chart/potential-slide-2-chart.component';

@Component({
  selector: 'app-potential-slide-1',
  templateUrl: './potential-slide-1.component.html',
  styleUrls: ['./potential-slide-1.component.scss'],
  imports: [PotentialSlide1ChartComponent, TranslocoModule, PotentialSlide2ChartComponent],
})
export class PotentialSlide1Component {
  next = output<void>();
  batteryAmount = input.required<number>();
  panelAmount = input.required<number>();

  constructor(public potentialService: PotentialService) {
    addIcons({
      add,
      arrowForwardOutline,
    });
  }

  chartData = computed(() => {
    const steps = this.potentialService.calculatePotentialSteps(this.panelAmount(), this.batteryAmount());
    const data = steps.map(step => step.kwh);
    const labels = steps.map(step => step.potential);

    return {
      data,
      labels,
    };
  });
}
