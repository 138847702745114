@if (panelAmount() < 4) {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.usePotential' | transloco }}</div>
    <div
      class="text-[16px] text-center mt-4 text-balance"
      [innerHTML]="
        'potentials.slide1-text1'
          | transloco
            : {
                perYear: (+potentialService.calculatePotentialYearlyKwh(
                  potentialService.monthlyData.data()?.monthly ?? []
                )).toFixed(2),
                percent: 'todo',
                startTime: 'todo',
                endTime: 'todo',
                optimalStartTime: 'todo',
                optimalEndTime: 'todo',
                optimalPerYear: chartData().data[chartData().data.length - 1].toFixed(2),
              }
      "></div>
    <div class="bg-[#F9FAFB] rounded px-2 pb-4 mt-4 pointer-events-none">
      <app-potential-slide-1-chart
        [potentials]="chartData().labels"
        [panelAmount]="panelAmount()"
        [data]="chartData().data"></app-potential-slide-1-chart>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-4">
    <div class="text-center font-bold text-[20px]">{{ 'potentials.usePotentialFour' | transloco }}</div>
    <div
      class="text-[16px] text-center mt-4 text-balance"
      [innerHTML]="
        'potentials.slide1-text1-four'
          | transloco
            : {
                perYear: (+potentialService.calculatePotentialYearlyKwh(
                  potentialService.monthlyData.data()?.monthly ?? []
                )).toFixed(2),
                percent: 'todo',
                startTime: 'todo',
                endTime: 'todo',
              }
      "></div>
    <div class="p-2 flex gap-2 items-center mt-4">
      <img
        src="assets/solakon/Solar-panel.png"
        class="w-12 mr-2"
        alt="" />
      <div>
        <div class="text-[16px] font-[500]">onPower</div>
        <div class="text-[14px] text-[#FFCA22]">2000W</div>
      </div>
    </div>
    <div class="px-2 pointer-events-none relative">
      <div class="text-[14px] text-black absolute z-1 right-[40%] top-[8px]">+513 €</div>
      <app-potential-slide-2-chart
        [potentials]="chartData().labels"
        [color]="['#FFCA22']"
        [data]="[513]"></app-potential-slide-2-chart>
    </div>
    <div
      class="text-[16px] text-center mt-4 text-balance"
      [innerHTML]="'potentials.slide1-text2-four' | transloco"></div>
    <div class="p-2 flex gap-2 items-center mt-4">
      <img
        src="assets/solakon/Solar-battery.png"
        class="w-12 mr-2"
        alt="" />
      <div>
        <div class="text-[16px] font-[500]">onPower + battery</div>
        <div class="text-[14px] text-[#2088FD]">+263 €</div>
      </div>
    </div>
    <div class="rounded px-2 pb-4 pointer-events-none relative">
      <div class="text-[14px] text-white absolute z-1 right-3 top-[8px]">+806 €</div>
      <app-potential-slide-2-chart
        [potentials]="chartData().labels"
        [color]="['#2088FD']"
        [data]="[806]"></app-potential-slide-2-chart>
    </div>
  </div>
}
