import { Component, computed, input, output } from '@angular/core';
import { addIcons } from 'ionicons';
import { add, arrowForwardOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/angular/standalone';
import { TranslocoModule } from '@jsverse/transloco';
import { PotentialService } from '../potential.service';
import { CommonModule } from '@angular/common';
import {BatteryStackComponent} from './battery-stack/battery-stack.component'

@Component({
  selector: 'app-potential-slide-3',
  templateUrl: './potential-slide-3.component.html',
  styleUrls: ['./potential-slide-3.component.scss'],
  imports: [IonIcon, TranslocoModule, CommonModule,BatteryStackComponent],
})
export class PotentialSlide3Component {
  readonly next = output<void>();

  batteryAmount = input.required<number>();
  panelAmount = input.required<number>();

  constructor(public potentialService: PotentialService) {
    addIcons({
      add,
      arrowForwardOutline,
    });
  }

  steps = computed(() => {
    return this.potentialService.calculatePotentialSteps(this.panelAmount(), this.batteryAmount());
  });
}
