import { Component, computed, input } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { ChartjsDirective } from 'src/app/directives/chartjs.directive';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CommonModule } from '@angular/common';
import { translate, TranslocoModule } from '@jsverse/transloco';

Chart.register(annotationPlugin);

@Component({
  selector: 'app-potential-slide-2-chart-four',
  templateUrl: './potential-slide-2-chart.component.html',
  styleUrls: ['./potential-slide-2-chart.component.scss'],
  imports: [ChartjsDirective, CommonModule, TranslocoModule],
})
export class PotentialSlide2ChartFourComponent {
  data = input.required<number[]>();
  panelAmount = input.required<number>();

  datasets = computed(() => ({
    labels: [
      translate('potentials.morning'),
      translate('potentials.lunchtime'),
      translate('potentials.evening')
    ],
    datasets: (() => {
      const dataValues = this.data();
      // const isMaxOneMore = dataValues.length === 2; 
      const isMaxOneMore = false; 
  
      const ds = [
        {
          data: [0, 600, 0],
          backgroundColor: 'rgb(239, 242, 245)',
          borderColor: '#FFCA22',
          borderDash: [3],
          fill: 'origin',
          pointStyle: false,
        },
        // dataValues[1] !== undefined
        //   ?
           {
              data: [0, 800, 0],
              backgroundColor: isMaxOneMore ? '#2088FD' : '#FFCA22',
              borderColor: isMaxOneMore ? '#2088FD' : '#FFCA22',
              fill: 'origin',
              pointStyle: false,
            },
          // : null,
        // dataValues[2] !== undefined && !isMaxOneMore
        //   ? 
          {
              data: [0, 990, 0],
              backgroundColor: '#2088FD',
              borderColor: '#2088FD',
              fill: 'origin',
              pointStyle: false,
            }
          // : null,
      ].filter((v): v is NonNullable<typeof v> => Boolean(v)); 
  
      return ds;
    })(),
  }));
  
  

  options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: { border: { display: true }, grid: { display: false } },
      y: {
        type: 'linear',
        border: {display: true},
        grid: {display: false},
        min: 0,
        max: 1000,
        ticks: { display: false },
        // afterBuildTicks: (axis) => {
        //   const customTicks = [250, 500, 600, 700, 800, 900, 1000];
        //   axis.ticks = customTicks.map((value) => ({ value }));
        // },
        // ticks: {
        //   callback: (value) => value + 'W',
        // },
      },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false,
      },
    },
  };
}
